<template>
	<pui-datatable
		:modelName="modelName"
		:externalFilter="externalFilter"
		:modelColumnDefs="columnDefs"
		:readOnly="true"
		:navigableDetail="false"
	></pui-datatable>
</template>

<script>
export default {
	data() {
		return {
			modelName: 'stopdifftimes',
			columnDefs: {
				reliabilityetastop: {
					render: (data, type, row, meta) => {
						return this.checkReliability(data, row, 'reliabilityetastop');
					}
				},
				reliabilityetaterminal: {
					render: (data, type, row, meta) => {
						return this.checkReliability(data, row, 'reliabilityetaterminal');
					}
				}
			}
		};
	},
	computed: {
		externalFilter() {
			let defaultQuay;
			if (this.$store.getters.isTerminalAPMT) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayAPMT;
			} else if (this.$store.getters.isTerminalTTIA) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayTTIA;
			} else if (this.$store.getters.isTerminalAPMTLC) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayAPMTLC;
			} else if (this.$store.getters.isTerminalHPLCT) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayHPLCT;
			} else {
				return {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') }]
				};
			}

			let op = 'eq';
			let data = defaultQuay.id;
			if (defaultQuay.groupIds) {
				op = 'in';
				data = defaultQuay.groupIds;
			}

			return {
				groupOp: 'and',
				groups: [],
				rules: [
					{ field: 'location_id', op, data },
					{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') }
				]
			};
		}
	},
	methods: {
		checkReliability(data, row, prop) {
			if (prop == 'reliabilityetastop') {
				if (!row.stopata || !row.stopeta) {
					return '';
				} else if (row.stopata >= row.stopeta) {
					return `<label class="arrived_later">${data}</label>`;
				} else {
					return `<label class="arrived_earlier">${data}</label>`;
				}
			} else if (prop == 'reliabilityetaterminal') {
				if (!row.ataterminal || !row.etaterminal) {
					return '';
				} else if (row.ataterminal >= row.etaterminal) {
					return `<label class="arrived_later">${data}</label>`;
				} else {
					return `<label class="arrived_earlier">${data}</label>`;
				}
			}
			return data;
		}
	}
};
</script>
<style>
.arrived_earlier {
	color: coral;
}
.arrived_later {
	color: green;
}
</style>
